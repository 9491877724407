<template>
  <div class="demo-content simple">
    <a-row type="flex" justify="center">
      <a-col :span="18">
        <div class="panel">
          <div class="panel__heading">
            <h3>{{ $t("missions.segments") }}</h3>
          </div>
          <div class="panel__body">
            <vddl-list
              class="panel__body--list"
              :list="list"
              :inserted="handleInserted"
              :dragover="handleDragover"
              :drop="handleDrop"
              :horizontal="false"
            >
              <vddl-draggable
                class="panel__body--item"
                v-for="(item, index) in list"
                :key="item.listId"
                :draggable="item"
                :index="index"
                :wrapper="list"
                effect-allowed="move"
                :selected="selectedEvent"
                :dragstart="handleDragstart"
                :dragend="handleDragend"
                :canceled="handleCanceled"
                :moved="handleMoved"
                v-bind:class="{
                  selected: isSelected(item),
                  unlinked: isSelected(item) ? false : existsInUnlinked(item),
                }"
              >
                {{ item.label }}
              </vddl-draggable>
              <vddl-placeholder class="red"
                >{{$t('missions.segmentPlaceholder')}}</vddl-placeholder
              >
            </vddl-list>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-row type="flex" justify="start">
      <a-col :offset="3">
        <a-button :disabled="selected === null" type="danger" @click="deleteSegment()">
          <a-icon type="delete" :title="$t('form.delete')" />
        </a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import Vue from "vue";
import Vddl from "vddl";

Vue.use(Vddl);

export default {
  name: "drag-drop-segments-mission",
  props: {
    eventBus: Object,
    segmentsMission: Array,
  },
  computed: {
    unlinked() {
      let unlinkedSegments = [];
      this.list.forEach((segment, index) => {
        if (index !== 0) {
          if (segment.firstPointId !== this.list[index - 1].secondPointId) {
            unlinkedSegments.push(segment);
          }
        }
      });
      if (unlinkedSegments.length !== 0) {
        this.eventBus.$emit("add-to-invalid-keys", "segments");
      } else {
        this.eventBus.$emit("delete-from-invalid-keys", "segments");
      }
      return unlinkedSegments;
    },
  },
  data() {
    return {
      selected: null,
      list: this.segmentsMission,
      lastId: "",
    };
  },
  methods: {
    deleteSegment() {
      if (this.selected !== null) {
        let index = this.list.findIndex((s) => s.index === this.selected.index);
        this.list.splice(index, 1);
        this.selected = null;
        this.eventBus.$emit("delete-segments", this.list);
      }
    },
    existsInUnlinked(item) {
      if (this.unlinked.includes(item)) {
        return true;
      } else {
        return false;
      }
    },
    isSelected(item) {
      if (this.selected !== null) {
        if (this.selected === item) {
          return true;
        }
      }
      return false;
    },
    selectedEvent: function(item) {
      if (this.selected !== null && this.selected === item) {
        this.selected = null;
      } else {
        this.selected = item;
      }
      this.eventBus.$emit("update-selected-segment", this.selected);
    },
    handleDrop(data) {
      const { index, list, item } = data;
      this.lastId = item.listId;
      item.listId = "newPosition";
      list.splice(index, 0, item);
    },
    handleMoved(item) {
      const { index, list } = item;
      list.splice(index, 1);
      let idIndex = this.list.findIndex((s) => s.listId === "newPosition");
      if (idIndex !== -1) {
        list[idIndex].listId = this.lastId;
        this.lastId = "";
      }
      this.eventBus.$emit("change-segments-position", list);
    },
    handleDragstart() {},
    handleDragend() {},
    handleCanceled() {},
    handleInserted() {},
    handleDragover() {},
  },
};
</script>

<style lang="less" scoped>
@item-height: 40px;
@placeholder-bg: #f5f5f5;

.vddl-list,
.vddl-draggable {
  position: relative;
}

.vddl-list {
  padding-left: 0px;
  min-height: @item-height;
}

.vddl-dragging {
  opacity: 0.7;
}

.vddl-dragging-source {
  display: none;
}

.panel {
  border-radius: 8px;
  border: 1px solid #eee;
  overflow: hidden;
  margin-bottom: 15px;
  p {
    display: inline-block;
    margin: 0;
  }
  &__heading {
    height: @item-height;
    line-height: @item-height;
    border-bottom: 1px solid #eee;
    background: #f5f5f5;
    h3 {
      font-size: 15px;
      padding: 0 15px;
      margin: 0;
    }
  }
  &__body {
    background: #fff;
    min-height: @item-height;
    > .panel {
      margin: 10px;
    }
    p {
      width: 100%;
      padding: 0 15px;
      font-size: 14px;
      line-height: 20px;
      color: #666;
    }
    &--list {
      background: #fff;
    }
    &--item,
    .panel__placeholder {
      width: 100%;
      min-height: @item-height;
      line-height: @item-height;
      border-bottom: 1px solid #eee;
      padding: 0 15px;
      background: #ffffff;
      box-sizing: border-box;
    }
    &--item.no-padding-left {
      padding-left: 0;
    }
    &--item:last-child {
      border-bottom: none;
    }
    &--item {
      > .panel {
        margin: 15px 0;
      }
    }
  }
  .panel__placeholder {
    background: @placeholder-bg;
  }
}

.panel.panel--info {
  .panel__body {
    padding: 25px;
  }
}

.selected {
  background-color: #f0de00;
}

.unlinked {
  border-color: red;
  border-top-color: red;
  border-bottom-color: red;
  border-left-color: red;
  border-right-color: red;
  background-color: #ffc4c4;
}
</style>

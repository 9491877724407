<template>
  <div v-if="flightPlanId !== null && dataLoaded">
    <a-row v-if="editingTarget === null" style="marginBottom: 20px" >
      <a-col
        :md="{
          span: 2,
          offset: layout === 'vertical' ? (target === null ? 3 : 3) : 3,
        }"
        :lg="{
          span: 2,
          offset: layout === 'vertical' ? (target === null ? 3 : 1) : 2,
        }"
        :xl="{
          span: 2,
          offset: layout === 'vertical' ? (target === null ? 5 : 1) : 2,
        }"
        :xxl="{
          span: 2,
          offset: layout === 'vertical' ? (target === null ? 5 : 1) : 2,
        }"
      >
        <a-button v-if="target !== null" @click="switchLayout">
          <a-icon type="border-horizontal" v-if="layout !== 'vertical'" />
          <a-icon type="border-verticle" v-else />
        </a-button>
      </a-col>
    </a-row>
    <a-row v-if="editingTarget !== null" style="margin-bottom: 10px;">
      <mission-edit-form
        :mission="editingTarget"
        :flightPlanId="flightPlanId"
        @save="save"
        @cancel="editingTarget = null"
      >
      </mission-edit-form>
    </a-row>
    <a-row type="flex" :gutter="12" v-else>
    <a-col
        :md="{
          span: layout === 'vertical'  ? (target === null ? 18 : 18) : 20,
          offset: layout === 'vertical' ? (target === null ? 3 : 3) : 3,
        }"
        :lg="{
          span: layout === 'vertical'  ? (target === null ? 15 : 15) : 20,
          offset: layout === 'vertical' ? (target === null ? 3 : 3) : 2,
        }"
        :xl="{
          span: layout === 'vertical'  ? (target === null ? 12 : 12) : 17,
          offset: layout === 'vertical' ? (target === null ? 5 : 5) : 2,
        }"
        :xxl="{
          span: layout === 'vertical'  ? (target === null ? 12 : 11) : 17,
          offset: layout === 'vertical' ? (target === null ? 5 : 1) : 2,
        }"
        :order="layout !== 'vertical' ? 2 : 1"
      >
        <a-row style="marginBottom: 8px">
          <a-col v-if="isFPManager" :span="2">
            <a-button
              :disabled="editingTarget !== null"
              class="btn btn-primary"
              type="primary"
              @click="addMission()"
            >
              {{ $t("form.add") }}
            </a-button>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            <a-table
              :columns="columns"
              :pagination="currentPage"
              :dataSource="missions"
              rowKey="id"
              @change="onTableChanged"
            >
              <template slot="missionName" slot-scope="text">
                {{ text }}
              </template>
              <template slot="description" slot-scope="text">
                <a-descriptions
                  v-if="text"
                  :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
                >
                  <a-descriptions-item>
                    {{ text }}
                  </a-descriptions-item>
                </a-descriptions>
              </template>
              <template slot="missionShow" slot-scope="text, record">
                <a-button
              @click="showMissionOnMap(record)"
            >
              {{ $t("missions.showMissions") }}
            </a-button>
              </template>
              <template slot="missionMap" slot-scope="text">
                {{ getMissionMapName(text) }}
              </template>
              <template slot="segments" slot-scope="text, record">
                <ol>
                  <li v-for="segment in getSegments(record)" :key="segment.id">
                    {{ segment.segmentName }}
                  </li>
                </ol>
              </template>
              <template slot="action" slot-scope="text, record">
                  <a-row>
                    <div class="edition-btn">
                      <a-dropdown :trigger="['click']">
                        <a-col :push="8">
                          <a-button
                            ><a-icon style="color: #124c5a" type="more"
                          /></a-button>
                        </a-col>
                        <a-menu slot="overlay">
                          <a-menu-item v-if="isFPManager" @click="edit(record)"
                            ><a-icon class="btn-icon" type="edit" />{{
                              $t("form.edit")
                            }}
                          </a-menu-item>
                          <a-menu-item
                            v-if="isFPManager"
                            @click="confirmDelete(record)"
                            ><a-icon class="btn-icon" type="delete" />{{
                              $t("form.delete")
                            }}
                          </a-menu-item>
                        </a-menu>
                      </a-dropdown>
                    </div>
                  </a-row>
              </template>
            </a-table>
          </a-col>
        </a-row>
      </a-col>
            <a-col
        v-if="target"
        :md="{
          span: layout === 'vertical'  ? 18 : (target === null ? 2 : 15),
          offset: layout === 'vertical' ? 4 : (target === null ? 1 : 1),
        }"
        :lg="{
          span: layout === 'vertical'  ? 15 : (target === null ? 2 : 15),
          offset: layout === 'vertical' ? 4 : (target === null ? 2 : 1),
        }"
        :xl="{
          span: layout === 'vertical'  ? 12 : (target === null ? 2 : 15),
          offset: layout === 'vertical' ? 4 : (target === null ? 2 : 1),
        }"
        :xxl="{
          span: layout === 'vertical'  ? 8 : (target === null ? 2 : 15),
          offset: layout === 'vertical' ? 1 : (target === null ? 2 : 1),
        }"
        :order="layout !== 'vertical' ? 1 : 2"
        class="map-container"
      >
        <a-row v-if="target">
          <a-col :span="4" :offset="0">
            <a-button
              @click="()=>{target = null; showMap = false}"
            >
            <a-icon :type="target == null ? 'right' : 'left'" />
              {{ $t("common.hideMap") }}
            </a-button>
          </a-col>
        </a-row>
        <a-row class="map-content">
          <missionMap
            :eventBus="eventBus"
            :segments="segments"
            :preview="preview_target"
            :missionSegments="target.segments"
            :segmentsNext="[]"
          />
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import Vue from "vue";
import {
  Table,
  Button,
  Row,
  Col,
  Icon,
  Modal,
  Descriptions,
  Card,
} from "ant-design-vue";
import MissionEditForm from "./MissionEditForm";
import { isFPManager } from "../../../utils/userAuth";
import { flightplanTypes } from "../../../store/mutation-types";
import MissionMap from "./MissionMap";

Vue.use(Table);
Vue.use(Button);
Vue.use(Row);
Vue.use(Col);
Vue.use(Card);
Vue.use(Icon);
Vue.use(Modal);
Vue.use(Descriptions);

let flightPlansUnwatcher = null;
let currentFlightPlanIdUnwatcher = null;

export default {
  name: "missionList",
  components: {
    MissionMap,
    MissionEditForm,
  },
  computed: {
    isFPManager() {
      return isFPManager();
    },
    columns() {
      const columns = [
        {
          dataIndex: "missionShow",
          width: "35%",
          scopedSlots: { customRender: "missionShow" },
        },
        {
          title: this.$t("common.name"),
          dataIndex: "missionName",
          width: "35%",
          scopedSlots: { customRender: "missionName" },
        },
        {
          title: this.$t("missions.description"),
          dataIndex: "description",
          width: "20%",
          scopedSlots: { customRender: "description" },
        },
      ];
      if (!this.isFPManager) {
        columns.push({
          title: this.$t("missions.missionMap"),
          dataIndex: "missionMapId",
          width: "15%",
          scopedSlots: { customRender: "missionMap" },
        });
        columns.push({
          title: this.$t("missions.segments"),
          dataIndex: "segments",
          width: "15%",
          scopedSlots: { customRender: "segments" },
        });
      }
      columns.push({
        dataIndex: "action",
        width: "20%",
        scopedSlots: { customRender: "action" },
      });
      return columns;
    },
    flightPlanId() {
      return this.$store.state.flightPlans.currentFlightPlanId;
    },
    missions() {
      return this.$store.getters.getFlightPlanById(this.flightPlanId).missions;
    },
    missionMaps() {
      return this.$store.getters.getFlightPlanById(this.flightPlanId)
        .missionMaps;
    },
    segments() {
      return this.$store.getters.getFlightPlanById(this.flightPlanId).segments;
    },
  },
    created() {
    this.layout = this.$store.getters.getUserPreferences().layout
    flightPlansUnwatcher = this.$store.subscribe((mutation) => {
      if (mutation.type === flightplanTypes.FETCH_FLIGHTPLANS) {
        this.showMap = false
        this.target = null
      }
    });
        currentFlightPlanIdUnwatcher = this.$store.watch(
      (state) => state.flightPlans.currentFlightPlanId,
      () => {
        this.showMap = false
        this.target = null
      }
    );

  },
  mounted() {
    if (this.flightPlanId && this.$store.state.flightPlans.lastFetch === null) {
      this.$store.dispatch("fetchFlightPlans").then(() => {
        this.dataLoaded = true;
      });
    } else {
      this.dataLoaded = true;
    }
  },
    beforeDestroy() {
    if (currentFlightPlanIdUnwatcher) {
      currentFlightPlanIdUnwatcher();
    }

    if (flightPlansUnwatcher) {
      flightPlansUnwatcher();
    }
  },
  data() {
    return {
      dataLoaded: false,
      editingTarget: null,
      target: null,
      showMap: null,
      preview_target: [],
      currentPage: {
        defaultCurrent: 1,
        current: 1,
      },
      selectedRows: [],
      selectedRowKeys: [],
      layout: "vertical",
      check_select: 1,
      eventBus: new Vue(),
    };
  },
  methods: {
    onSelectChange(selectedRowKeys, selectedRows) {
      this.showMap = true
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },

    switchLayout() {
      this.layout = this.layout === "vertical" ? "horizontal" : "vertical";
      this.$store.dispatch("setLayoutPreference", {
        preferences: {...this.$store.getters.getUserPreferences()},
              user: {...this.$store.getters.getCurrentUser()},
                            layout: this.layout,
      });
    },
    showMissionOnMap(mission) {
      this.target = null;
      this.target = mission;
      this.preview_target.length = 0
      this.segments.forEach((id) => {
        this.target.segments.forEach((dz) => {
          if (id.id === dz.segmentId)
            this.preview_target.push(id)
        })
      })
    },
    getSegments(mission) {
      let segmentNames = [];
      mission.segments.forEach((segment) => {
        segmentNames.push(
          this.segments.find((s) => s.id === segment.segmentId)
        );
      });
      return segmentNames;
    },
    getMissionMapName(id) {
      return this.missionMaps.find((m) => m.id === id).name;
    },
    onTableChanged(pagination) {
      this.currentPage = pagination.current;
    },
    addMission() {
      this.editingTarget = {
        id: "new",
        missionName: "",
        description: "",
        segments: [],
        missionMapId: null,
      };
    },
    edit(mission) {
      this.editingTarget = { ...mission };
    },
    save(mission) {
      if (this.editingTarget) {
        let isNew = mission.id === "new";
        let promise = null;

        if (isNew) {
          promise = this.$store.dispatch("addMission", {
            flightPlan: this.$store.getters.getFlightPlanById(
              this.flightPlanId
            ),
            mission,
          });
        } else {
          promise = this.$store.dispatch("updateMission", {
            flightPlan: this.$store.getters.getFlightPlanById(
              this.flightPlanId
            ),
            mission,
          });
        }

        promise.then(() => {
          this.editingTarget = null;
        });
      }
    },
    confirmDelete(mission) {
      Modal.confirm({
        title: this.$t("form.deleteConfirm"),
        onOk: () => {
          this.$store.dispatch("deleteMission", {
            flightPlan: this.$store.getters.getFlightPlanById(
              this.flightPlanId
            ),
            mission,
          });
        },
        onCancel: () => {},
      });
    },
  },
};
</script>

<style scoped lang="less"></style>

<template>
  <div class="mission-container">
    <a-row  style="marginBottom: 20px" >
          <a-col
        :md="{ span: 2, offset: 3 }"
        :lg="{ span: 2, offset: 2 }"
        :xl="{ span: 2, offset: 1 }"
        :xxl="{ span: 2, offset: 1 }"
      >
        <a-button @click="switchLayout">
          <a-icon type="border-horizontal" v-if="layout !== 'vertical'" />
          <a-icon type="border-verticle" v-else />
        </a-button>
      </a-col>
    </a-row>
    <a-row
      type="flex"
      justify="start"
      :gutter="12"
    >
      <a-col
        :md="{
          span: layout === 'vertical'  ? 17 : 17,
          offset: layout === 'vertical' ? 3 : 3,
        }"
        :lg="{
          span: layout === 'vertical'  ? 17 : 17,
          offset: layout === 'vertical' ? 3 : 3,
        }"
        :xl="{
          span: layout === 'vertical'  ? 10 : 17,
          offset: layout === 'vertical' ? 1 : 2,
        }"
        :xxl="{
          span: layout === 'vertical'  ? 10 : 17,
          offset: layout === 'vertical' ? 1 : 2,
        }"
        :order="layout !== 'vertical' ? 2 : 1"
        class="formMission"
      >
        <a-card :title="$t('missions.edition')">
          <a-row>
            <a-col>
              <a-form layout="vertical">
                <a-form-item
                  :label="$t('missions.name')"
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  :validate-status="getValidateStatus('missionName')"
                >
                  <a-input
                    :value="form.missionName"
                    @change="
                      (e) => handlePropChange('missionName', e.target.value)
                    "
                  />
                </a-form-item>

                <a-form-item
                  :label="$t('missions.segments')"
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  :validate-status="getValidateStatus('segments')"
                >
                  <a-select
                    style="width: 85%"
                    placeholder="Sélectionner les segments de la mission"
                    :value="selectedValue"
                    @change="handleChange"
                  >
                    <a-select-opt-group
                      :label="
                        filteredData.remaining.length !== 0
                          ? $t('missions.nextSegments')
                          : $t('missions.allSegments')
                      "
                    >
                      <a-select-option
                        v-for="segment in filteredData.next"
                        :key="segment.id"
                        :value="segment.id"
                      >
                        {{ segment.segmentName }}
                      </a-select-option>
                    </a-select-opt-group>
                    <a-select-opt-group :label="$t('missions.allSegments')">
                      <a-select-option
                        v-for="segment in filteredData.remaining"
                        :key="segment.id"
                        :value="segment.id"
                      >
                        {{ segment.segmentName }}
                      </a-select-option>
                    </a-select-opt-group>
                  </a-select>
                  <a-button
                    style="width: 14%; marginLeft: 1%"
                    @click="addSelectedValue()"
                  >
                    <a-icon style="marginLeft: -3px" type="check" :title="$t('form.add')"></a-icon>
                  </a-button>
                </a-form-item>

                <a-form-item>
                  <SegmentsList
                    :eventBus="eventBus"
                    :segmentsMission="selectedSegments"
                    :key="render"
                  />
                </a-form-item>

                <a-form-item
                  :label="$t('missions.missionMap')"
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  :validate-status="getValidateStatus('missionMapId')"
                >
                  <a-select
                    :value="form.missionMapId"
                    @change="(val) => handlePropChange('missionMapId', val)"
                  >
                    <a-select-option
                      v-for="m in missionMaps"
                      :key="m.id"
                      :value="m.id"
                    >
                      {{ m.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item
                  :label="$t('missions.description')"
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  :validate-status="getValidateStatus('description')"
                >
                  <a-textarea
                    :value="form.description"
                    @change="
                      (e) => handlePropChange('description', e.target.value)
                    "
                  >
                  </a-textarea>
                </a-form-item>
                <a-form-item>
                  <a-row type="flex" justify="center">
                    <a-col>
                      <a-space>
                        <a-button
                          @click="() => $emit('cancel')"
                          class="btn btn-danger"
                          type="danger"
                        >
                          {{ $t("form.cancel") }}
                        </a-button>
                        <a-button
                          @click="() => save()"
                          class="btn"
                          type="primary"
                        >
                        {{ $t("form.save") }}
                        </a-button>
                      </a-space>
                    </a-col>
                  </a-row>
                </a-form-item>
              </a-form>
            </a-col>
          </a-row>
        </a-card>
      </a-col>

      <a-col
        :md="{
          span: layout === 'vertical'  ? 15 : 15,
          offset: layout === 'vertical' ? 4 : 2,
        }"
        :lg="{
          span: layout === 'vertical'  ? 15 : 15,
          offset: layout === 'vertical' ? 4 : 2,
        }"
        :xl="{
          span: layout === 'vertical'  ? 8 : 15,
          offset: layout === 'vertical' ? 1 : 1,
        }"
        :xxl="{
          span: layout === 'vertical'  ? 8 : 15,
          offset: layout === 'vertical' ? 1 : 1,
        }"
        :order="layout !== 'vertical' ? 1 : 2"
        class="map-container"
      >
        <a-row class="map-content">
          <missionMap
            :eventBus="eventBus"
            :segments="segments"
            :missionSegments="form.segments"
            :addedSegments="missionSegments"
            :segmentsNext="filteredData.next"
          />
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import Vue from "vue";
import MissionMap from "./MissionMap";
import SegmentsList from "./MissionSegmentsList";
import { Input, Select, Button, Row, Col, Form, Card, Space } from "ant-design-vue";

Vue.use(Input);
Vue.use(Select);
Vue.use(Button);
Vue.use(Row);
Vue.use(Col);
Vue.use(Form);
Vue.use(Card);
Vue.use(Space);

export default {
  name: "mission-edit-form",
  components: {
    MissionMap,
    SegmentsList,
  },
  props: {
    mission: Object,
    flightPlanId: String,
  },
  computed: {
    filteredData() {
      let nextIds = [],
        remainingIds = [];
      if (this.targetIds.length === 0) {
        nextIds = this.startInBase();
        if (Object.keys(nextIds).length == 1)
          this.handleChange(nextIds[0].id)
      } else {
        nextIds = this.nextSegments(this.targetIds[this.targetIds.length - 1]);
        if (Object.keys(nextIds).length == 1)
          this.handleChange(nextIds[0].id)
      }

      if (nextIds.length === 0) {
        nextIds = this.segments;
      }

      nextIds.map((s) =>
        Object.assign({ id: s.id.toString(), title: s.segmentName }, s)
      );

      remainingIds = this.segments.filter(
        (segment) => !nextIds.includes(segment)
      );
      remainingIds.map((s) =>
        Object.assign({ id: s.id.toString(), title: s.segmentName }, s)
      );

      let cluster = {
        next: nextIds,
        remaining: remainingIds,
      };
      return cluster;
    },
    segments() {
      return this.$store.getters
        .getFlightPlanById(this.flightPlanId)
        .segments.map((s) =>
          Object.assign({ id: s.id.toString(), title: s.segmentName }, s)
        );
    },
    points() {
      return this.$store.getters.getFlightPlanById(this.flightPlanId).points;
    },
    missionMaps() {
      return this.$store.getters.getFlightPlanById(this.flightPlanId)
        .missionMaps;
    },
    selectedSegments() {
      let selected = [];
      this.targetIds.forEach((id, index) => {
        let segment = this.segments.find((segment) => segment.id === id);
        if (segment) {
          selected.push({
            listId: `${segment.id}-${index}`,
            id: segment.id,
            index: index,
            label: segment.title,
            firstPointId: segment.firstPointId,
            secondPointId: segment.secondPointId,
          });
        }
      });
      return selected;
    },
    targetIds() {
      if (this.missionSegments.length > 0) {
        return this.missionSegments.map((s) => s.id.toString());
      }
      return this.form.segments.map((s) => s.segmentId.toString());
    }
  },
  mounted() {
    this.loadMissionSegments();

    this.eventBus.$on("change-segments-position", this.onChangeSegmentsPosition);
    this.eventBus.$on("update-selected-segment", this.onUpdateSelectedSegment);
    this.eventBus.$on("add-to-invalid-keys", this.onAddToInvalidFields);
    this.eventBus.$on("delete-from-invalid-keys", this.onDeleteFromInvalidFields);
    this.eventBus.$on("delete-segments", this.deleteSegments);
    this.eventBus.$on("select-segment", this.onSelectSegment);

    this.layout = this.$store.getters.getUserPreferences().layout
  },
  data() {
    return {
      eventBus: new Vue(),
      form: {},
      formItemLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 18 },
      },
      invalidFields: [],
      missionSegments: [],
      selectedValue: undefined,
      render: 0,
      selectedSegment: null,
      layout: "vertical",
    };
  },
  methods: {
    switchLayout() {
      this.layout = this.layout === "vertical" ? "horizontal" : "vertical";
      this.$store.dispatch("setLayoutPreference", {
        preferences: {...this.$store.getters.getUserPreferences()},
              user: {...this.$store.getters.getCurrentUser()},
                            layout: this.layout,
      });

    },
    onSelectSegment(segmentId) {
      if (this.selectedValue !== segmentId) {
        this.selectedValue = segmentId.toString();
      } else {
        this.selectedValue = undefined;
      }
    },
    onUpdateSelectedSegment(item) {
      this.selectedSegment = item;
    },
    onAddToInvalidFields(field) {
      let fieldIndex = this.invalidFields.findIndex(
        (element) => element === field
      );
      if (fieldIndex === -1) {
        this.invalidFields.push(field);
      }
    },
    onDeleteFromInvalidFields(field) {
      let popIndex = this.invalidFields.findIndex(
        (element) => element === field
      );
      this.invalidFields.splice(popIndex, 1);
    },
    deleteSegments(list) {
      let toDelete = [];
      this.missionSegments.forEach((s) => {
        const found = list.find((segment) => segment.index === s.index);
        if (!found) {
          toDelete.push(s);
        }
      });

      toDelete.forEach((s) => {
        let idxDelete = this.missionSegments.findIndex(
          (segment) => s.index === segment.index
        );
        if (idxDelete !== -1) {
          this.missionSegments.splice(idxDelete, 1);
        }
      });

      this.missionSegments = this.MapIndexMissionSegments();

      this.selectedSegment = null;
      this.selectedValue = undefined;
    },
    MapIndexMissionSegments() {
      return this.missionSegments.map((s, index) => {
        return {
          ...s,
          listId: `${s.id}-${index}`,
          index: index,
        };
      });
    },
    onChangeSegmentsPosition(newPositionsList) {
      this.missionSegments = newPositionsList;
    },
    startInBase() {
      let baseStartSegment = [];
      this.segments.forEach((segment) => {
        let index = this.points.findIndex(
          (point) => point.id === segment.firstPointId
        );
        if (index > -1) {
          if (this.points[index].pointType === "basepoint") {
            baseStartSegment.push(segment);
          }
        }
      });
      baseStartSegment.map((s) =>
        Object.assign({ id: s.id.toString(), title: s.segmentName }, s)
      );
      return baseStartSegment;
    },
    nextSegments(lastSelectedSegmentId) {
      let nextSelection = [],
        lastSelectedSegment = null;
      lastSelectedSegment = this.segments.find(
        (s) => s.id === lastSelectedSegmentId
      );
      nextSelection = this.segments.filter(
        (segment) => segment.firstPointId === lastSelectedSegment.secondPointId
      );
      nextSelection.map((s) =>
        Object.assign({ id: s.id.toString(), title: s.segmentName }, s)
      );
      return nextSelection;
    },
    loadMissionSegments() {
      this.targetIds.forEach((segmentId, index) => {
        let segment = this.segments.find((s) => s.id === segmentId);
        if (segment) {
          this.missionSegments.push({
            listId: `${segment.id}-${index}`,
            id: segment.id,
            index: index,
            label: segment.title,
            firstPointId: segment.firstPointId,
            secondPointId: segment.secondPointId,
          });
        }
      });
    },
    addSelectedValue() {
      let invalidIdx = this.invalidFields.findIndex((f) => f === "segments");
      if (invalidIdx !== -1) {
        this.invalidFields.splice(invalidIdx, 1);
      }
      let segment = this.segments.find((s) => s.id === this.selectedValue);
      if (segment) {
        if (this.selectedSegment != null) {
          let index = this.missionSegments.findIndex(
            (s) => s.id === this.selectedSegment.id
          );
          if (index !== -1) {
            index++;
            this.missionSegments.splice(index, 0, {
              id: segment.id,
              label: segment.title,
              firstPointId: segment.firstPointId,
              secondPointId: segment.secondPointId,
            });
          }
        } else {
          this.missionSegments.push({
            id: segment.id,
            label: segment.title,
            firstPointId: segment.firstPointId,
            secondPointId: segment.secondPointId,
          });
        }
      }

      this.missionSegments = this.MapIndexMissionSegments();

      this.eventBus.$emit("add-segment", this.selectedValue);
      this.render++;
      this.selectedValue = undefined;
    },
    handleChange(value) {
      this.selectedValue = value;
    },
    searchSegment(segmentId) {
      let segment = this.segments.find((s) => s.id === segmentId);
      if (segment) {
        return {
          id: segment.id,
          label: segment.segmentName,
        };
      }
    },
    handlePropChange(prop, val) {
      this.form[prop] = val;
    },
    getValidateStatus(prop) {
      const idx = this.invalidFields.indexOf(prop);

      if (idx !== -1) {
        return "error";
      }
      return "";
    },
    loadSegmentsToSave() {
      this.form.segments = this.missionSegments.map((segment, index) => {
        return { index: index, segmentId: segment.id };
      });
    },
    save() {
      this.loadSegmentsToSave();
      if (this.form.missionName == "") {
        this.invalidFields.push("missionName");
      }

      if (this.form.segments.length === 0) {
        this.invalidFields.push("segments");
      }

      if (this.form.missionMapId === null) {
        this.invalidFields.push("missionMapId");
      }

      if (this.invalidFields.length === 0) {
        this.$emit("save", this.form);
      }
    },
  },
  watch: {
    mission: {
      immediate: true,
      handler: function(missionFromProps) {
        if (missionFromProps) {
          this.form = {
            ...this.form,
            ...missionFromProps
          }
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.edit-buttons {
  text-align: left;
  .ant-btn-danger {
    margin-left: 2%;
  }
}

.formMission {
  margin-top: 18px;
}

</style>

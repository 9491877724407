<script>
import Gmaps from "../../Gmaps";
import { defaultMapCenter } from "../../Gmaps";
import { getColoredIconUrl } from "../../../utils/IconUrlGenerator";
import { isFPManager } from "../../../utils/userAuth";
import { iconColors } from "../../MarkerIcon/iconColors"

let markers = [];
let polylineSegments = [];
let polylinesMission = [];

const segmentColors = {
  SELECTED: "#00e4f0",
  NOTSELECTED: "#F0DE00",
  NEXTSEGMENT: "#124C5A",
};

function numAverage(a) {
  var b = a.length,
      c = 0, i;
  for (i = 0; i < b; i++){
    c += Number(a[i]);
  }
  return c/b;
}

export default {
  name: "missionMap",
  mixins: [Gmaps],
  props: {
    eventBus: Object,
    segments: Array,
    missionSegments: Array,
    segmentsNext: Array,
    preview: Array,
    addedSegments: Array,
  },
  computed: {
    flightPlanId() {
      return this.$store.state.flightPlans.currentFlightPlanId;
    },
    points() {
      return this.$store.getters.getFlightPlanById(this.flightPlanId).points;
    },
  },
  methods: {
    resetMapConfig() {
      const basePoint = this.points.find((p) => p.pointType === "basepoint");
      const mapCenter = basePoint
        ? { lat: basePoint.latitude, lng: basePoint.longitude }
        : defaultMapCenter;
      this.map.setZoom(20);
      this.map.setCenter(mapCenter);
    },
    resetSegments() {
      this.removeAllSegments();
      this.segments.forEach((segment) => {
        this.addSegment(segment);
      });
    },
    resetPoints() {
      this.removeAllPoints();
      this.points.forEach((p) => {
        this.addMarker(p);
      });
    },
    addMission() {
      this.missionSegments.forEach((segmentObj) => {
        this.addMissionSegment(segmentObj.segmentId);
      });
    },
    addNextSegments() {
      this.segmentsNext.forEach((nextSegment) => {
        let exists = this.missionSegments.find(
          (s) => s.segmentId === nextSegment.id
        );
        if (!exists) {
          let segmentObj = polylineSegments.find(
            (s) => s.segment.id === nextSegment.id
          );
          if (segmentObj) {
            segmentObj.polyline.setOptions({
              strokeColor: "#124C5A",
            });
          }
        }
      });
    },
    addMissionSegment(segmentId) {
      let segment = this.searchSegment(segmentId);
      let path = this.createPath(segment);
      let newPolyline = new this.google.maps.Polyline({
        path: path,
        visible: true,
        map: this.map,
        strokeColor: segmentColors.SELECTED,
        strokeOpacity: 1.0,
        strokeWeight: 3,
      });
      if (isFPManager()) {
        newPolyline.addListener("click", () => {
          this.eventBus.$emit("select-segment", segment.id);
        });
      }
      let polylineObj = {
        segmentId: segmentId,
        polyline: newPolyline,
      };
      polylinesMission.push(polylineObj);

      let polySegment = polylineSegments.find(
        (ps) => ps.segment.id === segmentId
      );
      if (polySegment) {
        polySegment.polyline.setVisible(false);
      }
    },
    removeMissionSegment(segmentId) {
      const index = polylinesMission.findIndex(
        (polylineObj) => polylineObj.segmentId === segmentId
      );
      if (index !== -1) {
        polylinesMission[index].polyline.setMap(null);
        polylinesMission.splice(index, 1);
      }
      let polySegment = polylineSegments.find(
        (ps) => ps.segment.id === segmentId
      );
      if (polySegment) {
        polySegment.polyline.setVisible(true);
      }
    },
    refreshMissionsPath(toDelete, toAdd) {
      toDelete.forEach((id) => {
        this.removeMissionSegment(id);
      });

      toAdd.forEach((id) => {
        const found = polylinesMission.find(
          (polylineObj) => polylineObj.segmentId === id
        );
      if (!found) {
        this.addMissionSegment(id);
        }
      });
    },
    searchSegment(segmentId) {
      let segmentObj = polylineSegments.find(
        (sp) => sp.segment.id === segmentId
      );
      if (segmentObj) {
        return segmentObj.segment;
      } else {
        return null;
      }
    },
    searchPoint(pointId) {
      let pointObj = markers.find(
        (markerObj) => markerObj.point.id === pointId
      );
      if (pointObj) {
        return pointObj.point;
      } else {
        return null;
      }
    },
    addSegment(segment) {
      let pathPolyline = this.createPath(segment);

      let polyline = new this.google.maps.Polyline({
        path: pathPolyline,
        strokeColor: segmentColors.NOTSELECTED,
        strokeOpacity: 1.0,
        strokeWeight: 3,
        map: this.map,
        visible: isFPManager(),
      });
      if (isFPManager()) {
        polyline.addListener("click", () => {
          this.eventBus.$emit("select-segment", segment.id);
        });
      }

      let segmentPolylineObj = {
        segment: segment,
        polyline: polyline,
      };
      polylineSegments.push(segmentPolylineObj);
    },
    createPath(segment) {
      let path = [];
      let firstPoint, secondPoint;

      if (segment.firstPointId && segment.secondPointId) {
        let markerObj = markers.find((m) => m.point.id == segment.firstPointId);
        if (markerObj) {
          firstPoint = new this.google.maps.LatLng(
            parseFloat(markerObj.point.latitude),
            parseFloat(markerObj.point.longitude)
          );
        }
        markerObj = markers.find((m) => m.point.id == segment.secondPointId);
        if (markerObj) {
          secondPoint = new this.google.maps.LatLng(
            parseFloat(markerObj.point.latitude),
            parseFloat(markerObj.point.longitude)
          );
        }
        path = [firstPoint, secondPoint];
      }

      return path;
    },
    removeSegment(segment) {
      const polylineSegmentIndex = polylineSegments.findIndex(
        (sp) => sp.segment.id === segment.id
      );
      if (polylineSegmentIndex !== -1) {
        polylineSegments[polylineSegmentIndex].polyline.setMap(null);
        polylineSegments.splice(polylineSegmentIndex, 1);
      }
    },
    removeAllSegments() {
      polylineSegments.forEach((segmentPolylineObj) => {
        segmentPolylineObj.polyline.setMap(null);
      });
      polylineSegments = [];
    },
    addMarker(point) {
      var marker = new this.google.maps.Marker({
        position: {
          lat: point.latitude,
          lng: point.longitude,
        },
        icon: {
          url: getColoredIconUrl(
            iconColors[point.pointType.toUpperCase()],
            "#333333",
            point.pointType
          ),
          anchor: { x: 14, y: 14 },
        },
        map: this.map,
        draggable: false,
        title: point.pointName,
      });
      let markerObj = {
        point: point,
        marker: marker,
      };
      markers.push(markerObj);
    },
    removePoint(point) {
      const markerObjIndex = markers.findIndex(
        (marker) => marker.point.id === point.id
      );
      if (markerObjIndex !== -1) {
        markers[markerObjIndex].marker.setMap(null);
        markers.splice(markerObjIndex, 1);
      }
    },
    removeAllPoints() {
      markers.forEach((m) => {
        m.marker.setMap(null);
      });
      markers = [];
    },
    existsInMissionPath(segment) {
      let index = polylinesMission.findIndex((s) => s.segmentId === segment.id);
      if (index !== -1) {
        return true;
      } else {
        return false;
      }
    },
  },
  async mounted() {
    await this.mapInitPromise;
    if (typeof this.preview == 'undefined')
      segmentColors.NOTSELECTED = "#F0DE00"
    else
      segmentColors.NOTSELECTED = "transparent"

    this.resetMapConfig();
    this.map.setZoom(this.$store.getters.getZoom());
    if (this.$store.getters.getCenter().lat && this.$store.getters.getCenter().lng)
      this.map.setCenter(this.$store.getters.getCenter());
    this.resetPoints();
    this.resetSegments();
    this.addMission();
    this.addNextSegments();
  },
  beforeDestroy() {
    polylinesMission.length = 0
    polylineSegments.length = 0
    this.$store.commit("SET_ZOOM", this.map.getZoom())

    if (this.map.getBounds().lc && this.map.getBounds().Eb) {
      var lat = numAverage([this.map.getBounds().lc.g, this.map.getBounds().lc.i]);
      var lng = numAverage([this.map.getBounds().Eb.g,this.map.getBounds().Eb.i])
      this.$store.commit('SET_CENTER', {
        lat: lat,
        lng: lng
      })
    }
    this.removeAllSegments();
    this.removeAllPoints();
  },
  watch: {
    addedSegments: function() {
      this.addedSegments.forEach((segmentObj) => {
        let selectedPolylineSegment = polylineSegments.find((s) => s.segment.id === segmentObj.id);
        if (selectedPolylineSegment != undefined) {
          if (selectedPolylineSegment) {
            selectedPolylineSegment.polyline.setOptions({
              strokeColor: segmentColors.SELECTED,
            });
          }
        }
      });
      polylineSegments.forEach((polylineSegment) => {
        var length_addedSegments = this.addedSegments.length
        var length = 0
        this.addedSegments.forEach((segment) => {
          if (segment.id != polylineSegment.segment.id)
            length++;
        })
        if (length_addedSegments == length) {
          polylineSegment.polyline.setOptions({
            strokeColor: segmentColors.NOTSELECTED,
          });
        }
      })
    },
    missionSegments: function(newMissionSegments, oldMissionSegments) {
      if (newMissionSegments.length !== 0 && oldMissionSegments.length === 0) {
        newMissionSegments.forEach((missionSegmentObj) => {
          const found = polylinesMission.find(
            (polylineObj) =>
              polylineObj.segmentId === missionSegmentObj.segmentId
          );
          if (!found) {
            this.addMissionSegment(missionSegmentObj.segmentId);
          }
        });
      } else if (
        newMissionSegments.length !== 0 &&
        oldMissionSegments.length !== 0
      ) {
        let segmentsToAdd = [],
          segmentsToDelete = [];

        oldMissionSegments.forEach((oldSegmentObj) => {
          const found = newMissionSegments.find(
            (nSegmentObj) => nSegmentObj.segmentId === oldSegmentObj.segmentId
          );
          if (!found) {
            segmentsToDelete.push(oldSegmentObj.segmentId);
          }
        });

        newMissionSegments.forEach((newSegmentObj) => {
          const found = oldMissionSegments.find(
            (oldSegmentObj) =>
              oldSegmentObj.segmentId === newSegmentObj.segmentId
          );
          if (!found) {
            segmentsToAdd.push(newSegmentObj.segmentId);
          }
        });

        this.refreshMissionsPath(segmentsToDelete, segmentsToAdd);
      } else {
        oldMissionSegments.forEach((segmentObj) => {
          this.removeMissionSegment(segmentObj.segmentId);
        });
      }
    },
    segmentsNext: function(newSegmentsToShow, oldSegmentsToShow) {
      oldSegmentsToShow.forEach((oldSegment) => {
        const found = newSegmentsToShow.find(
          (newSegment) => newSegment.id === oldSegment.id
        );
        if (!found) {
          let segmentObj = polylineSegments.find(
            (s) => s.segment.id === oldSegment.id
          );
        }
      });

      newSegmentsToShow.forEach((newSegment) => {
        const found = oldSegmentsToShow.find(
          (oldSegment) => oldSegment.id === newSegment.id
        );
        if (!found) {
          let segmentObj = polylineSegments.find(
            (s) => s.segment.id === newSegment.id
          );
          if (segmentObj) {
            if (!this.existsInMissionPath(segmentObj.segment.id)) {
              segmentObj.polyline.setOptions({
                strokeColor: segmentColors.NEXTSEGMENT,
              });
            }
          }
        }
      });
    },
    segments: function(newSegments, oldSegments) {
      oldSegments.forEach((oldSegment) => {
        this.removeSegment(oldSegment);
      });
      newSegments.forEach((newSegment) => {
        this.addSegment(newSegment);
      });
    },
    points: function(newPoints, oldPoints) {
      oldPoints.forEach((oldPoint) => {
        this.removePoint(oldPoint);
      });
      newPoints.forEach((newPoint) => {
        this.addMarker(newPoint);
      });
    },
  },
};
</script>
